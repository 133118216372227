import React from 'react';
import Social from '../components/social/social';

class SocialPage extends React.Component
{
  render(){
    return (
        <Social />
    );
  }
  
}

export default SocialPage;

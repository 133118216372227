import Icontact from "model/contact";
import config from "./config.json";

class contactservice {

    public static SendContact(contact: Icontact) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/contact/SendContact`, { method: "post", body: JSON.stringify(contact), headers: myHeaders })
    }
}

export default contactservice;

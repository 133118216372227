import React, { useState } from 'react'
import config from 'services/config.json';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FileExplorer, { computeFullPath } from 'components/common/fileExplorer/fileExplorer';

interface IFileFolderSelectorProps {
    folder?: boolean,
    onSelect: Function,
    filter?: Function,
    value: string
}


interface IFileFolderSelectorState {
    modal: boolean;
    currentSelection?: string;
}

const FileFolderSelector = (props: IFileFolderSelectorProps) => {

    const [modal, setModal] = useState<boolean>(false);
    const [currentSelection, setCurrentSelection] = useState<string | undefined>();

    const toggle = () => {
        setModal(!modal);
        setCurrentSelection(undefined)
    }

    const saveChange = () => {
        props.onSelect(currentSelection);
        toggle();
    }

    const changeSelection = (itemSelected: any) => {
        if (props.folder ? itemSelected.rowData.type == 'dir' : itemSelected.rowData.type == 'file' && (!props.filter || props.filter(itemSelected.rowData.name))) {
            setCurrentSelection(`${config.wsUrl != '' ? config.wsUrl : window.location.origin}/${config.rootFolderPath}${computeFullPath(itemSelected.rowData)}`);
        }
        else {
            setCurrentSelection(undefined);
        }
    }

    return (
        <div>
            <span>{props.value || 'Aucun'}</span>{' '}
            <Button onClick={toggle}>Selectionner un {props.folder ? 'dossier' : 'fichier'}</Button>
            <Modal style={{ width: "70vw", maxWidth: "100vw" }} isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{props.folder ? 'dossier' : 'fichier'}</ModalHeader>
                <ModalBody style={{ height: '300px' }}>
                    <FileExplorer onClick={changeSelection} />
                </ModalBody>
                <ModalFooter>
                    <Button color={currentSelection ? "primary" : "secondary"} onClick={saveChange} disabled={!currentSelection}>Sauvegarder</Button>{' '}
                    <Button color="danger" onClick={toggle}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default FileFolderSelector;
import React, { useEffect, useState } from 'react'
import IActualite from 'model/actualite';
import ActualitesService from 'services/actualitesService';
import Header from 'components/common/header/header';
import Styles from "./actualite.module.scss";
import { Markup } from 'interweave';
import { Container } from 'reactstrap';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';

interface IActualiteProps {
    id: number;
    setHeaderValues: Function
}

interface IActualiteState {
    actu?: IActualite;
}

const Actualite = (props: IActualiteProps) => {

    const [actu, setActu] = useState<IActualite>()


    useEffect(() => {
        ActualitesService.GetActu(props.id).then((a: IActualite) => {
            setActu(a);
            props.setHeaderValues({ headerPictureUrl: a.PictureUrl, headerTitle: a.Title, headerUrl: undefined });
        });
    }, []);

    if (actu) {
        let extraContent = [];
        extraContent.push(actu.IsEvent ? <div>Date : {actu.StartDate ? actu.StartDate.toLocaleString() : null} -  {actu.EndDate ? actu.EndDate.toLocaleString() : null}</div> : null);
        if (actu.Location)
            extraContent.push(<div>Lieu : {actu.Location}</div>);

        if (actu.Spectacles)
            extraContent.push(<div>Spectacles : {actu.Spectacles.map(s =>
                <a href={`/#/spectacle/${s.Id}`}>{s.Title}</a>
            )}</div>);

        if (actu.Decors)
            extraContent.push(<div>Decors : {actu.Decors.map(d =>
                <a href={`/#/decor/${d.Id}`}>{d.Title}</a>
            )}</div>);

        return <div className={Styles.Actualite}>
            <Container>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <a href="/#/actualites">Voir toutes les actualités</a>
                        <div className="imgIllustration">
                            <img src={actu.PictureUrl} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <p className="date">
                            {extraContent}
                        </p>
                        <p className="chapo">
                            {actu.Chapo}
                        </p>
                        <p className="content">
                            <Markup content={actu.Content} />
                        </p>

                        <div className="social">
                            <p>Partagez sur les réseaux sociaux :</p>
                            <div className="icons">
                                <FacebookShareButton url={window.location.href} quote={encodeURIComponent(actu.Title)} hashtag={'#CieRelativement'} ><FacebookIcon size={32} round={true} /></FacebookShareButton>
                                <TwitterShareButton url={window.location.href} title={actu.Title} hashtags={["CieRelativement"]} ><TwitterIcon size={32} round={true} /></TwitterShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    }
    else
        return null;
}

export default Actualite;
import React from "react";
import Styles from "./relatifCard.module.scss";
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from "reactstrap";
import IRelatif from "model/lesRelatifs";
import Picture from "components/common/picture/picture";
import { Markup } from "interweave";

interface IRelatifProps {
    relatif: IRelatif;
    complete: boolean
}


const RelatifCard = (props: IRelatifProps) => {

    return (
        <Card className={Styles.RelatifCard + (!props.complete ? " " + Styles.white : "")}>
            <div className={Styles.avatarWrapper}>
                <Picture className={Styles.avatar} RenditionID={3} PictureUrl={encodeURI(props.relatif.PictureUrl)} />
            </div>
            <CardBody>
                <CardTitle><h3>{props.relatif.Name}</h3></CardTitle>
                <CardText><Markup content={props.relatif.DescriptionCourte} /></CardText>
                {
                    props.complete ?
                        <CardText>
                            <Markup content={props.relatif.DescriptionLongue} />
                            {props.relatif.Email ? <div>Mail perso : <a href={'mailto:' + props.relatif.Email}>{props.relatif.Email}</a></div>
                                : null}
                            {props.relatif.Site ? <div>Site perso : <a href={props.relatif.Site}>{props.relatif.Site}</a></div>
                                : null}
                        </CardText>
                        : null
                }
            </CardBody>
        </Card>
    )
}

export default RelatifCard;

import React from 'react';
import Header from 'components/common/header/header';
import Spectacles from 'components/spectacles/spectacles';

class SpectaclesPage extends React.Component<{ setHeaderValues: Function }> {

  componentDidMount() {
    this.props.setHeaderValues({ headerUrl: 'spectacles', headerTitle: undefined, headerPictureUrl: undefined })
  }

  render() {
    return (
      <div>
        <Spectacles />
      </div>
    );
  }

}

export default SpectaclesPage;

import React, { useEffect, useState } from 'react'

import Styles from "./adminActus.module.scss";
import IActualite from 'model/actualite';
import ActualitesService from 'services/actualitesService';
import { Table, Button, Input, Form, FormGroup, Label, Container } from 'reactstrap';
import DateTimePicker from 'react-datetime-picker'
import confirm from 'reactstrap-confirm';
import { isPicture } from 'components/common/fileExplorer/fileExplorer';
import FileFolderSelector from 'components/common/fileFolderSelector/fileFolderSelector';
import Editor from 'components/common/editor/editor';


const AdminActus = () => {

    const [actuEdition, setActuEdition] = useState<IActualite | undefined>(undefined);
    const [actus, setActus] = useState<IActualite[]>([]);

    const load = () => {
        ActualitesService.GetAllActus().then(actus => {
            setActus(actus.sort((a: IActualite, b: IActualite) => { return a.Id < b.Id ? -1 : 1 }));
            setActuEdition(undefined);
        })
    }

    useEffect(() => {
        load();
    }, []);

    const saveActu = (actu: IActualite) => {
        ActualitesService.SetActu(actu).then(load);
    }

    const setActuToDelete = (actu: IActualite) => {
        confirm({
            title: 'Suppression de l\'actualité',
            message: "Etes vous sûr de vouloir supprimer l'actualité ?",
            confirmText: "Supprimer",
            confirmColor: "primary",
            cancelColor: "danger"
        }).then((res: any) => {
            if (res) {
                return ActualitesService.DeleteActu(actu).then(load);
            }
        });
    }

    const dateToYMD = (date: Date) => {
        if (!date)
            return undefined;
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    const saveChangeMainPicture = (value: string) => {
        if (actuEdition) {
            setActuEdition({ ...actuEdition, PictureUrl: value })
        }
    }

    return <div className={Styles.AdminActus}>
        <Container>

            <Button color="primary" onClick={setActuEdition.bind(null, { Content: '', Chapo: '', Title: '', Id: 0, IsALaUne: false, IsEvent: false, StartDate: undefined, EndDate: undefined, PublicationDate: new Date(Date.now()), PictureUrl: '' } as IActualite)}>Créer une actualité</Button>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Titre</th>
                        <th>Date de publication</th>
                        <th>A la une ?</th>
                        <th>Editer</th>
                        <th>Supprimer</th>
                    </tr>
                </thead>
                <tbody>
                    {actus.map(a => {
                        return <tr key={a.Id} className={Styles.ActuRow}>
                            <th scope="row">{a.Id}</th>
                            <td>{a.Title}</td>
                            <td>{a.PublicationDate.toDateString()}</td>
                            <td>{a.IsALaUne ? 'Oui' : 'Non'}</td>
                            <td><Button color="primary" onClick={setActuEdition.bind(null, a)}>Editer</Button></td>
                            <td><Button color="danger" onClick={setActuToDelete.bind(null, a)}>Supprimer</Button></td>
                        </tr>
                    })}
                </tbody>

            </Table>
            {actuEdition ?

                <div className={Styles.Edition}>
                    {actuEdition.Id ? <h2>Edition de l'actualité {actuEdition.Id}</h2> : <h2>Création d'actualité</h2>}
                    <Form>
                        <FormGroup>
                            <Label for="inputTitle">Titre</Label>
                            <Input id="inputTitle" placeholder="Titre" value={actuEdition.Title} onChange={(newText) => {
                                if (actuEdition)
                                    setActuEdition({ ...actuEdition, Title: newText.target.value })
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inputChapo">Chapô</Label>
                            <Input placeholder="Chapô" value={actuEdition.Chapo} onChange={(newText) => {
                                if (actuEdition)
                                    setActuEdition({ ...actuEdition, Chapo: newText.target.value })
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inputContent">Contenu</Label>
                            <Editor onChange={(newText) => {
                                if (actuEdition)
                                    setActuEdition({ ...actuEdition, Content: newText })
                            }} initialValue={actuEdition.Content} />

                        </FormGroup>
                        <FormGroup>
                            <Label for="inputPictureUrl">Image principale</Label>
                            <FileFolderSelector filter={isPicture} onSelect={saveChangeMainPicture} value={actuEdition.PictureUrl} />

                        </FormGroup>
                        <FormGroup>
                            <Label for="inputPublicationDate">Date de publication</Label>
                            <Input id="inputPublicationDate" type="date" value={dateToYMD(actuEdition.PublicationDate)} onChange={(newText) => {
                                if (actuEdition)
                                    setActuEdition({ ...actuEdition, PublicationDate: new Date(Date.parse(newText.target.value)) })
                            }} />
                        </FormGroup>
                        <FormGroup check>
                            <Label for="cbIsALaUne" check><Input id="cbIsALaUne" type="checkbox" checked={actuEdition.IsALaUne} onChange={(newText) => {
                                if (actuEdition)
                                    setActuEdition({ ...actuEdition, IsALaUne: newText.target.checked })
                            }} />{' '}
                                    Mettre à la une</Label>

                        </FormGroup>
                        <FormGroup check>
                            <Label for="cbIsEvent" check>
                                <Input id="cbIsEvent" type="checkbox" checked={actuEdition.IsEvent} onChange={(newText) => {
                                    if (actuEdition)
                                        setActuEdition({ ...actuEdition, IsEvent: newText.target.checked })
                                }} />{' '}Evenement ?
                                </Label>

                        </FormGroup>
                        {actuEdition.IsEvent ?
                            <FormGroup>
                                <Label for="inputStartDate">Date de début</Label>{' '}
                                <DateTimePicker value={actuEdition.StartDate} onChange={(newText: any) => {
                                    if (actuEdition)
                                        setActuEdition({ ...actuEdition, StartDate: newText })
                                }} />
                            </FormGroup>

                            : null}
                        {actuEdition.IsEvent ?
                            <FormGroup>
                                <Label for="inputEndDate">Date de fin</Label>{' '}
                                <DateTimePicker value={actuEdition.EndDate} onChange={(newText: any) => {
                                    if (actuEdition)
                                        setActuEdition({ ...actuEdition, EndDate: newText })
                                }} />
                            </FormGroup>

                            : null}
                    </Form>
                    <Button color="primary" onClick={saveActu.bind(null, actuEdition)}>Sauvegarder</Button>
                    <Button color="danger" onClick={() => setActuEdition(undefined)}>Annuler</Button>
                </div>
                : null
            }
        </Container>
    </div>

}

export default AdminActus;
import React, { useEffect, useState } from 'react';
import Styles from './carrouselActus.module.scss';
import { Carousel, CarouselItem, CarouselIndicators, CarouselControl } from 'reactstrap';
import ActualitesService from "../../services/actualitesService";
import IActualite from 'model/actualite';
import CarrouselItem from './carrouselItem';



const CarrouselActus = () => {
  var animating: boolean = false;
  const [actus, setActus] = useState<IActualite[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    ActualitesService.GetActusALaUne().then(actus => {
      setActus(actus);
    })
  }, []);

  var onExiting = () => {
    animating = true;
  }

  var onExited = () => {
    animating = false;
  }

  var next = () => {
    if (animating) return;
    const nextIndex = currentIndex === actus.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);
  }

  var previous = () => {
    if (animating) return;
    const nextIndex = currentIndex === 0 ? actus.length - 1 : currentIndex - 1;
    setCurrentIndex(nextIndex);
  }

  var goToIndex = (newIndex: number) => {
    if (animating) return;
    setCurrentIndex(newIndex);
  }

  let slides = actus.map(a => <CarouselItem key={a.Id}><CarrouselItem actu={a} /></CarouselItem>);
  return (
    <div className={Styles.CarrouselActus}>
      <Carousel
        activeIndex={currentIndex}
        next={next}
        previous={previous}
        pause={'hover'}
      >
        <CarouselIndicators items={actus} activeIndex={currentIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </div>
  );





}

export default CarrouselActus;

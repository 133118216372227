import config from "./config.json";
import IGeneral from "model/general";


class GeneralService {
    public static GeneralRep: Promise<IGeneral>;

    static GetGeneral(): Promise<IGeneral> {
        if (!this.GeneralRep) {
            this.Load();
        }
        return this.GeneralRep;
    }

    static Load() {
        this.GeneralRep = fetch(`${config.wsUrl}/api/general/GetGeneral`).then(res => {
            return res.json();
        });
    }

    static SetGeneral(gen: IGeneral) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/general/SetGeneral`, { method: "post", body: JSON.stringify({ ...gen, token: localStorage.getItem("AuthToken") }), headers: myHeaders }).then(this.Load.bind(this))
    }
}

export default GeneralService;
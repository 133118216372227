import React from "react";
import { Markup } from 'interweave';
import Styles from "./presentation.module.scss";
import { Container, Col, Row } from 'reactstrap';
import GeneralService from "services/generalService";
import Picture from "components/common/picture/picture";
import Relatif from "components/relatif/relatif";

interface IPresentationProps {
}

interface IPresentationState {
    contentTop: string;
    contentLeft: string;
    contentRight: string;
    contentBottom: string;
    pictureLeft: string;
    pictureRight: string;
}

class Presentation extends React.Component<IPresentationProps, IPresentationState>{

    /**
     *
     */
    constructor(props: IPresentationProps) {
        super(props);

        this.state = { contentTop: '', contentLeft: '', contentRight: '', contentBottom: '', pictureLeft: '', pictureRight: '' } as IPresentationState
    }

    componentDidMount() {
        GeneralService.GetGeneral().then(gen => {
            this.setState({ contentTop: gen.PresentationHaut, contentLeft: gen.PresentationGauche, contentRight: gen.PresentationDroite, contentBottom: gen.PresentationBas, pictureLeft: gen.PresentationPhotoGauche, pictureRight: gen.PresentationPhotoDroite })
        })
    }

    render() {
        return (
            <div className={Styles.Presentation}>
                <Container>
                    <Markup content={this.state.contentTop} />
                    <Relatif isComplete={false} />
                    <Markup content={this.state.contentBottom} />
                </Container>
            </div>
        );
    }
}

export default Presentation;
import React from "react";
import Styles from "./decor.module.scss";
import IDecor from "model/decor";
import { Markup } from "interweave";
import DecorsService from "services/decorsService";
import { Container, Col, Row } from 'reactstrap';
import Header from "components/common/header/header";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

interface IDecorProps {
    id: number
    setHeaderValues: Function
}

interface IDecorsState {
    decor?: IDecor
}

class Decor extends React.Component<IDecorProps, IDecorsState> {
    /**
     *
     */
    constructor(props: IDecorProps) {
        super(props);

        this.state = { decor: undefined };
    }

    componentDidMount() {
        DecorsService.GetDecor(this.props.id).then(d => {
            this.setState({ decor: d });
            this.props.setHeaderValues({ headerPictureUrl: d.MainPictureUrl, headerTitle: d.Title, headerUrl: undefined })
        })
    }

    render() {
        if (this.state.decor) {
            return <div className={Styles.Decor}>
                <Container>
                    <Row className="my-5">
                        <Col xs="12">
                            <div className="mt-5">
                                <Markup content={this.state.decor.Content} />
                            </div>
                            {this.state.decor.Pictures.length ?
                                <ReactImageGallery items={this.state.decor.Pictures.map(p => { return { original: p.Url, thumbnail: p.Url, thumbnailTitle: p.Title, originalTitle: p.Title } })} />
                                : null
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        }
        else {
            return null;
        }
    }
}

export default Decor
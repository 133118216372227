import React from 'react';
import FacebookLogin from 'react-facebook-login';
import config from '../../services/config.json'
import AdminPage from '../../pages/admin.page';
import { Container } from 'reactstrap';

//source : https://medium.com/@alexanderleon/implement-social-authentication-with-react-restful-api-9b44f4714fa
interface IFBLoginState {
    isAuthenticated: boolean,
    user: any,
    token: string
}

class FbLogin extends React.Component<{ setHeaderValues: Function }, IFBLoginState>
{
    constructor(props: { setHeaderValues: Function }) {
        super(props);
        if (localStorage.getItem('User') && localStorage.getItem('TokenValidity') && parseInt(localStorage.getItem('TokenValidity') || '0') > Date.now()) {
            this.state = { isAuthenticated: true, user: JSON.parse(localStorage.getItem('User') as string), token: localStorage.getItem("AuthToken") as string };
        }
        else {
            this.state = { isAuthenticated: false, user: null, token: '' };
        }
    }

    logout() {
        this.setState({ isAuthenticated: false, token: '', user: null })
        localStorage.removeItem("AuthToken");
        localStorage.removeItem("TokenValidity");
        localStorage.removeItem("User");
    };

    facebookResponse(response: any) {
        const tokenBlob = new Blob([JSON.stringify({ access_token: response.accessToken }, null, 2)], { type: 'application/json' });
        const options: RequestInit = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',
            cache: 'default'
        };
        fetch(`${config.wsUrl}/api/auth/facebook`, options).then((r: any) => {
            const token = r.headers.get('x-auth-token');
            r.json().then((user: any) => {
                if (token) {
                    this.setState({ isAuthenticated: true, user, token })
                    localStorage.setItem("AuthToken", token);
                    localStorage.setItem("User", JSON.stringify(user));
                    localStorage.setItem("TokenValidity", (Date.now() + 60 * 120 * 1000).toString());
                }
            });
        })
    };

    onFailure(error: any) {
        alert(error);
    }

    post() {
        const contentBlob = new Blob([JSON.stringify({
            token: this.state.token,
            message: 'n\'importe quoi d\'autre 1'
        }, null, 2)], { type: 'application/json' });

        const options: RequestInit = {
            method: 'POST',
            body: contentBlob,
            mode: 'cors',
            cache: 'default'
        };
        fetch(`${config.wsUrl}/api/social/facebook`, options).then((r: any) => {

        })
    }

    render() {
        let content = !!this.state.isAuthenticated ?
            (
                <div>
                    <AdminPage setHeaderValues={this.props.setHeaderValues} />
                    <Container>
                        <span>Connecté en tant que : {this.state.user.fullname}</span>
                        <button onClick={this.logout.bind(this)}>Se deconnecter</button>
                    </Container>
                </div>
            ) :
            (
                <div>
                    <FacebookLogin
                        appId={config.facebook.appId}
                        autoLoad={false}
                        fields="name,email,picture,manage_pages,publish_pages"
                        callback={this.facebookResponse.bind(this)} />
                </div>
            );

        return (
            <div className="App">
                {content}
            </div>
        );
    }
}

export default FbLogin;

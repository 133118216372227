import React from 'react';
import Styles from './carrouselItem.module.scss';
import IActualite from 'model/actualite';
import Picture from 'components/common/picture/picture';

interface ICarrouselItemState {
}

interface ICarrouselItemProps {
  actu: IActualite,
}

const CarrouselItem = (props: ICarrouselItemProps) => {

  return <div className={Styles.CarrouselItem}>
    <Picture className="CarrouselPict d-block w-100 h-100" RenditionID={1} PictureUrl={encodeURI(props.actu.PictureUrl)} />
    <div className="CarrouselText">
      <h2>{props.actu.Title}</h2>
      <a href={`/#/actualite/${props.actu.Id}`}>Voir l'actualité</a>
    </div>
  </div>


}

export default CarrouselItem;

import React from "react";
import Styles from "./spectacle.module.scss";
import ISpectacle from "model/spectacle";
import { Markup } from "interweave";
import SpectaclesService from "services/spectaclesService";
import { Container, Col, Row } from 'reactstrap';
import Header from "components/common/header/header";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
interface ISpectacleProps {
    id: number,
    setHeaderValues: Function
}

interface ISpectacleState {
    spectacle?: ISpectacle
}

class Spectacle extends React.Component<ISpectacleProps, ISpectacleState> {
    /**
     *
     */
    constructor(props: ISpectacleProps) {
        super(props);

        this.state = { spectacle: undefined };
    }

    componentDidMount() {
        SpectaclesService.GetSpectacle(this.props.id).then(s => {
            this.setState({ spectacle: s });
            this.props.setHeaderValues({ headerPictureUrl: s.MainPictureUrl, headerTitle: s.Title, headerUrl: undefined })
        });
    }

    render() {
        if (this.state.spectacle) {
            return <div className={Styles.Spectacle}>
                <Container>
                    <Row>
                        <Col xs="12">
                            <div className="mt-5">
                                <Markup content={this.state.spectacle.Content} />
                            </div>
                            {this.state.spectacle.Pictures.length ?
                                <ReactImageGallery items={this.state.spectacle.Pictures.map(p => { return { original: p.Url, thumbnail: p.Url, thumbnailTitle: p.Title, originalTitle: p.Title } })} />
                                : null
                            }
                        </Col>
                    </Row>
                </Container>

            </div>
        }
        else {
            return null;
        }

    }
}

export default Spectacle
import React from 'react';
import Styles from './actualiteCard.module.scss';
import IActualite from 'model/actualite';
import { Card, CardBody, CardTitle, CardText, CardSubtitle } from "reactstrap";
import Picture from 'components/common/picture/picture';

interface IActualiteCardProps {
  actu: IActualite
}


const ActualiteCard = (props: IActualiteCardProps) => {

  return (
    <Card className={Styles.ActualiteCard}>
      <a href={`/#/actualite/${props.actu.Id}`} className="c-preview">
        <Picture PictureUrl={encodeURI(props.actu.PictureUrl)} RenditionID={2} className="c-preview__img" />
      </a>
      <CardBody>
        <CardTitle>{props.actu.Title}</CardTitle>
        <CardSubtitle>{props.actu.PublicationDate.toLocaleDateString()}</CardSubtitle>
        <CardText>{props.actu.Chapo}</CardText>
        <a href={`/#/actualite/${props.actu.Id}`} className="card-link underlined underlined--thick">Lire la suite</a>
      </CardBody>
    </Card>
  );

}

export default ActualiteCard;

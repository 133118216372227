import React, { useEffect, useState } from 'react'

import Styles from "./adminDecors.module.scss";
import { Table, Button, Input, Form, FormGroup, Label, Container } from 'reactstrap';
import Editor from 'components/common/editor/editor';
import confirm from 'reactstrap-confirm';
import IDecors from 'model/decor';
import decorsService from 'services/decorsService';
import IFileInfo from 'model/fileInfo';
import { isPicture } from 'components/common/fileExplorer/fileExplorer';
import FileFolderSelector from 'components/common/fileFolderSelector/fileFolderSelector';


const AdminDecors = () => {
    const [decorEdition, setDecorEdition] = useState<IDecors | undefined>(undefined)
    const [decors, setDecors] = useState<IDecors[]>([]);
    const [folders, setFolders] = useState<IFileInfo[]>([]);

    const load = () => {
        Promise.all(
            [
                decorsService.GetAllDecors(),
                decorsService.GetPictureFolder()
            ]
        )
            .then(value => {
                setDecors(value[0].sort((a: IDecors, b: IDecors) => { return a.Id < b.Id ? -1 : 1 }));
                setDecorEdition(undefined);
                setFolders(value[1]);
            })
    }

    useEffect(() => {
        load();
    }, []);


    const savedecor = (decor: IDecors) => {
        decorsService.SetDecor(decor).then(load);
    }


    const setdecorToDelete = (decor: IDecors) => {
        confirm({
            title: 'Suppression du decor',
            message: "Etes vous sûr de vouloir supprimer le decor ?",
            confirmText: "Supprimer",
            confirmColor: "primary",
            cancelColor: "danger"
        }).then((res: any) => {
            if (res) {
                return decorsService.DeleteDecor(decor).then(load);
            }
        });
    }


    const saveChangeFolder = (value: string) => {
        if (decorEdition)
            setDecorEdition({ ...decorEdition, PictureFolder: value });
    }


    const saveChangeMainPicture = (value: string) => {
        if (decorEdition)
            setDecorEdition({ ...decorEdition, MainPictureUrl: value });
    }

    return <div className={Styles.AdminDecors}>
        <Container>

            <Button color="primary" onClick={setDecorEdition.bind(null, {} as IDecors)}>Créer un decor</Button>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Titre</th>
                        <th>Editer</th>
                        <th>Supprimer</th>
                    </tr>
                </thead>
                <tbody>
                    {decors.map(s => {
                        return <tr key={s.Id} className={Styles.decorRow}>
                            <th scope="row">{s.Id}</th>
                            <td>{s.Title}</td>
                            <td><Button color="primary" onClick={setDecorEdition.bind(null, s)}>Editer</Button></td>
                            <td><Button color="danger" onClick={setdecorToDelete.bind(null, s)}>Supprimer</Button></td>
                        </tr>
                    })}
                </tbody>

            </Table>
            {decorEdition ?

                <div className={Styles.Edition}>
                    {decorEdition.Id ? <h2>Edition du decor {decorEdition.Id}</h2> : <h2>Création de decors</h2>}
                    <Form>
                        <FormGroup>
                            <Label for="inputTitle">Titre</Label>
                            <Input id="inputTitle" placeholder="Titre" value={decorEdition.Title} onChange={(newText) => {
                                if (decorEdition)
                                    setDecorEdition({ ...decorEdition, Title: newText.target.value })
                            }} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="inputContent">Contenu</Label>
                            <Editor onChange={(newText) => {
                                if (decorEdition)
                                    setDecorEdition({ ...decorEdition, Content: newText })

                            }} initialValue={decorEdition.Content} />

                        </FormGroup>
                        <FormGroup>
                            <Label for="inputPictureUrl">Image principale</Label>
                            <FileFolderSelector filter={isPicture} onSelect={saveChangeMainPicture} value={decorEdition.MainPictureUrl} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inputPictureFolder">Dossier d'images</Label>
                            <FileFolderSelector folder onSelect={saveChangeFolder} value={decorEdition.PictureFolder} />
                            {decorEdition.Pictures ?
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {decorEdition.Pictures.map(p => <div><img src={p.Url} width={200} />  <Input id="inputTitlePicture" placeholder="Titre" value={p.Title} onChange={(newText) => {
                                        if (decorEdition)
                                            setDecorEdition({ ...decorEdition, Pictures: decorEdition.Pictures.map(pict => { return pict.Url != p.Url ? pict : { Url: p.Url, Title: newText.target.value } }) })
                                    }} /></div>)}
                                </div>
                                : null}
                        </FormGroup>
                    </Form>
                    <Button color="primary" onClick={savedecor.bind(null, decorEdition)}>Sauvegarder</Button>
                    <Button color="danger" onClick={() => setDecorEdition(undefined)}>Annuler</Button>
                </div>
                : null
            }
        </Container>
    </div>

}

export default AdminDecors;
import React from "react";
import Styles from "./pictureCrop.module.scss";
import GeneralService from "services/generalService";
import IRendition from "model/rendition";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { string } from "prop-types";
import { FormGroup, Label, Input, Button } from "reactstrap";
import RenditionsService from "services/renditionsService";
import config from "services/config.json";
import path from 'path';

interface IPictureCropProps {
    PictureUrl: string,
    Picturedata: any,
    OnSave: Function
}

interface IPictureCropState {
    crops: { [key: number]: ReactCrop.Crop };
    src: string;
    croppedImages: { [key: number]: Blob };
    Renditions: IRendition[];

}

class PictureCrop extends React.Component<IPictureCropProps, IPictureCropState>{

    private imageRef?: HTMLImageElement;
    private fileUrl: string = '';
    /**
     *
     */
    constructor(props: IPictureCropProps) {
        super(props);
        this.imageRef = undefined;
        this.state = {
            src: props.PictureUrl,
            crops: [{
                unit: "px",
                width: 300,
                height: 200,
                aspect: 3 / 2
            }],
            croppedImages: {},
            Renditions: [],
        };
    }

    renditionToCrop(rendition: IRendition): ReactCrop.Crop {
        var refWidth = this.imageRef ? this.imageRef.width : 50;
        var ratio = rendition.Width / rendition.Height
        return {
            unit: "px",
            aspect: ratio,
            width: refWidth,
            height: refWidth / ratio,
            x: 0,
            y: 0
        }
    }

    componentDidMount() {
        RenditionsService.GetAllRenditions().then(rs => {
            var crops = {} as { [key: number]: ReactCrop.Crop };
            rs.forEach(r => {
                crops[r.Id] = this.renditionToCrop(r)
                this.makeClientCrop(r.Id, crops[r.Id])
            })

            this.setState({ Renditions: rs, crops: crops });
        })
    }


    getCroppedImg(image: HTMLImageElement, crop: ReactCrop.Crop, fileName: string): Promise<Blob | undefined> {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = (crop.width || 0) * scaleX;
        canvas.height = (crop.height || 0) * scaleY;
        const ctx = canvas.getContext("2d");

        if (!ctx)
            return Promise.resolve(undefined);

        ctx.drawImage(
            image,
            (crop.x || 0) * scaleX,
            (crop.y || 0) * scaleY,
            (crop.width || 0) * scaleX,
            (crop.height || 0) * scaleY,
            0,
            0,
            (crop.width || 0) * scaleX,
            (crop.height || 0) * scaleY,
        );

        console.log(typeof ctx.getImageData(0, 0, canvas.width, canvas.height).data[0]);

        return new Promise<Blob>((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                resolve(blob);
            }, this.imageRef && this.imageRef.src.endsWith("png") ? "image/png" : "image/jpeg");
        });
    }

    // If you setState the crop in here you should return false.
    onImageLoaded(image: HTMLImageElement) {
        this.imageRef = image;
        var crops = {} as { [key: number]: ReactCrop.Crop };
        this.state.Renditions.forEach(r => {
            crops[r.Id] = this.renditionToCrop(r)
            this.makeClientCrop(r.Id, crops[r.Id])
        })

        this.setState({ crops: crops });
        return false;
    };

    onCropComplete(renditionId: number, crop: ReactCrop.Crop) {
        this.makeClientCrop(renditionId, crop);
    };

    onCropChange(renditionId: number, crop: ReactCrop.Crop, percentCrop: ReactCrop.PercentCrop) {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crops: { ...this.state.crops, [renditionId]: crop } });
    };

    async makeClientCrop(renditionId: number, crop: ReactCrop.Crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImage = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            if (!croppedImage)
                return;
            this.setState({
                croppedImages: {
                    ...this.state.croppedImages,
                    [renditionId]: croppedImage
                }
            });
        }
    }
    render() {
        const { src } = this.state;

        return <div className={Styles.PictureCrop}>

            {this.state.Renditions.map(r =>
                <div>
                    <h4>Rendu de {r.Title} : </h4>
                    <ReactCrop
                        src={src}
                        crop={this.state.crops[r.Id]}
                        onImageLoaded={this.onImageLoaded.bind(this)}
                        onComplete={this.onCropComplete.bind(this, r.Id)}
                        onChange={this.onCropChange.bind(this, r.Id)}
                        crossorigin="anonymous"
                    />
                </div>
            )}
            <Button color="primary" onClick={this.saveRendition.bind(this)}>Sauvegarder</Button>
        </div>
    }

    saveRendition() {
        var items;
        fetch(`${config.wsUrl}/files/${this.props.Picturedata.parentId}/children?orderBy=name&orderDirection=ASC`).then(res => res.json()).then(
            (res) => {
                return res.items;
            }
        ).then((sibling) => {
            var promises: FormData[] = [];
            var delpromises: string[] = [];

            for (var key in this.state.croppedImages) {
                let formData = new FormData();
                formData.append('type', 'file');
                var filename = this.props.Picturedata.name as string
                var name = filename.substring(0, filename.lastIndexOf(".")); // hello
                var ext = filename.substring(filename.lastIndexOf(".") + 1);  // .html
                //formData.append('name', `${name}-rd${key}.${ext}`);
                var filecroppedname = `${name}-rd${key}.${ext}`;
                sibling.forEach((sib: any) => {
                    if (sib.name == filecroppedname) {
                        delpromises.push(`${config.wsUrl}/files/${sib.id}`)
                    }
                });

                formData.append('parentId', this.props.Picturedata.parentId);
                formData.append('files', this.state.croppedImages[key], filecroppedname);
                promises.push(formData)
            }
            return Promise.all(delpromises.map(u => fetch(u, { method: "delete" }))).then(() => Promise.all(promises.map(p => fetch(`${config.wsUrl}/files`, { method: "post", body: p }))));
        }).then(() => this.props.OnSave());


    }
}

export default PictureCrop;
import IActualite from "model/actualite";
import config from "./config.json";

class ActualitesService {
    public static GetActusALaUne(): Promise<IActualite[]> {
        return fetch(`${config.wsUrl}/api/actualites/GetActualitesALaUne/${config.limitActusCarousel}`).then(resp => {
            return resp.json().then(res => { return res.map(this.ReadActus) });
        });
    }

    public static GetLastActus(): Promise<IActualite[]> {
        return fetch(`${config.wsUrl}/api/actualites/GetLastActualites/${config.limitActus}`).then(resp => {
            return resp.json().then(res => { return res.map(this.ReadActus) });
        });
    }

    public static GetAllActus(): Promise<IActualite[]> {
        return fetch(`${config.wsUrl}/api/actualites/GetLastActualites/0`).then(resp => {
            return resp.json().then(res => { return res.map(this.ReadActus) });
        });
    }

    public static GetAgenda(): Promise<IActualite[]> {
        return fetch(`${config.wsUrl}/api/actualites/GetAgenda`).then(resp => {
            return resp.json().then(res => { return res.map(this.ReadActus) });
        });

    }

    public static GetActu(id: number) {
        return fetch(`${config.wsUrl}/api/actualites/GetActu/${id}`).then(resp => {
            return resp.json().then(res => { return this.ReadActus(res) });
        });
    }

    public static SetActu(actu: IActualite) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/actualites/SetActu`, { method: "post", body: JSON.stringify({ ...actu, token: localStorage.getItem("AuthToken") }), headers: myHeaders })
    }

    public static DeleteActu(actu: IActualite) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/actualites/DeleteActu/${actu.Id}`, { method: "post", body: JSON.stringify({ token: localStorage.getItem("AuthToken") }), headers: myHeaders });
    }

    static ReadActus(actu: any) {

        return {
            ...actu,
            StartDate: actu.StartDate ? new Date(Date.parse(actu.StartDate)) : undefined,
            EndDate: actu.EndDate ? new Date(Date.parse(actu.EndDate)) : undefined,
            PublicationDate: new Date(Date.parse(actu.PublicationDate)),
        }
    }
}

export default ActualitesService;

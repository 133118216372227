import React from "react";
import Styles from "./header.module.scss";
import GeneralService from "services/generalService";
import Picture from "../picture/picture";

interface IHeaderProps {
    UrlPage?: string,
    PictureUrl?: string,
    Title?: string
}

interface IHeaderState {
    PictureUrl: string,
    Title: string
}

class Header extends React.Component<IHeaderProps, IHeaderState>{

    /**
     *
     */
    constructor(props: IHeaderProps) {
        super(props);
        this.state = { PictureUrl: this.props.PictureUrl || '', Title: this.props.Title || '' };
    }


    componentDidUpdate(prevProps: IHeaderProps) {
        if (this.props.UrlPage != prevProps.UrlPage || this.props.PictureUrl != prevProps.PictureUrl || this.props.Title != prevProps.Title) {
            if (this.props.UrlPage && !this.props.PictureUrl && !this.props.Title) {
                this.componentDidMount();
            }
            else {
                this.setState({ PictureUrl: this.props.PictureUrl || '', Title: this.props.Title || '' })
            }
        }

    }

    componentDidMount() {
        if (this.props.UrlPage) {
            GeneralService.GetGeneral().then(gen => {
                var p = gen.Pages[this.props.UrlPage || ''];
                this.setState({ PictureUrl: p.PictureUrl, Title: p.Title })
            })
        }
    }

    render() {
        return <Picture className={Styles.Header} RenditionID={4} PictureUrl={encodeURI(this.state.PictureUrl)}>
            <h1>{this.state.Title}</h1>
        </Picture>
    }
}

export default Header;
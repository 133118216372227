import React, { useEffect, useState } from "react";
import Styles from "./relatif.module.scss";
import { Markup } from "interweave";
import RelatifService from "services/relatifsService";
import { Col, Container, Row } from "reactstrap";
import IRelatif from "model/lesRelatifs";
import { CircleLoader } from "react-spinners";
import RelatifCard from "./relatifCard";

interface IRelatifProps {
  isComplete: boolean;
}

const Relatif = (props: IRelatifProps) => {

  const [relatifs, setRelatifs] = useState<IRelatif[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);


  useEffect(() => {
    RelatifService.GetAllRelatifs().then(setRelatifs).then(() => setIsLoading(false));
  }, [])

  return (
    <Container>
      <CircleLoader
        css={"margin: auto; margin-top: 20px;"}
        sizeUnit={"vw"}
        size={20}
        color={'rgb(95, 156, 148)'}
        loading={isLoading}
      />
      <Row className="my-5">
        {relatifs.map(a => <Col key={a.Id} xs="12" md="4" xl="4"> <RelatifCard relatif={a} complete={props.isComplete} /></Col>)}
      </Row>
    </Container>
  );

}

export default Relatif;

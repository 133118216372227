import config from "./config.json";
import IRendition from "model/rendition";

class RenditionsService {
    public static GetAllRenditions(): Promise<IRendition[]> {
        return fetch(`${config.wsUrl}/api/renditions/GetAllRenditions`).then(resp => {
            return resp.json();
        })
    }

}

export default RenditionsService;

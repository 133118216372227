import React from 'react';
import Styles from './picture.module.scss';

interface IPictureProps extends React.HTMLAttributes<HTMLDivElement>{
    PictureUrl: string;
    RenditionID: number;
}

interface IPictureState{
    TruePictureUrl: string;

}
export default class Picture extends React.Component<IPictureProps, IPictureState>{

    /**
     *
     */
    constructor(props:IPictureProps) {
        super(props);
        
        this.state={TruePictureUrl: ''};
    }

    componentDidMount(){
        var filename = this.props.PictureUrl
        var name = filename.substring(0, filename.lastIndexOf(".")); // hello
        var ext = filename.substring(filename.lastIndexOf(".")+1);  // .html
        var filecroppedname = `${name}-rd${this.props.RenditionID}.${ext}`;

        fetch(filecroppedname).then((res) => this.setState({TruePictureUrl: res.ok && res.headers.get("content-type") != 'text/html; charset=UTF-8' ? filecroppedname : filename})).catch(() => this.setState({TruePictureUrl: filename}))
    }

    componentDidUpdate(prevprops: IPictureProps){
        if(prevprops.PictureUrl != this.props.PictureUrl)
            this.componentDidMount();
    }

    render(){
        var propsToSend = {...this.props} as any;
        propsToSend.PictureUrl = undefined;
        propsToSend.RenditionID = undefined;
        return <div style={{backgroundImage: `url("${this.state.TruePictureUrl}")`}} className={Styles.Picture + ' '+this.props.className} {...propsToSend}></div>
    }
}
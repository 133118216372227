import React, { useEffect, useState } from 'react'

import Styles from "./adminSpectacles.module.scss";
import { Table, Button, Input, Form, FormGroup, Label, Container } from 'reactstrap';
import confirm from 'reactstrap-confirm';
import ISpectacle from 'model/spectacle';
import SpectaclesService from 'services/spectaclesService';
import IFileInfo from 'model/fileInfo';
import { isPicture } from 'components/common/fileExplorer/fileExplorer';
import FileFolderSelector from 'components/common/fileFolderSelector/fileFolderSelector';
import Editor from 'components/common/editor/editor';


interface IAdminSpectaclesState {
    spectacleEdition?: ISpectacle;
    spectacles: ISpectacle[];
    folders: IFileInfo[]
}

const AdminSpectacles = () => {

    const [spectacleEdition, setSpectacleEdition] = useState<ISpectacle | undefined>(undefined);
    const [spectacles, setSpectacles] = useState<ISpectacle[]>([]);
    const [folders, setFolders] = useState<IFileInfo[]>([]);

    const load = () => {
        Promise.all(
            [
                SpectaclesService.GetAllSpectacles(),
                SpectaclesService.GetPictureFolder()
            ]
        )
            .then(value => {
                setSpectacles(value[0].sort((a: ISpectacle, b: ISpectacle) => { return a.Id < b.Id ? -1 : 1 }));
                setSpectacleEdition(undefined);
                setFolders(value[1]);
            })
    }

    useEffect(() => {
        load();
    }, []);


    const saveSpectacle = (spect: ISpectacle) => {
        SpectaclesService.SetSpectacle(spect).then(load);
    }


    const setSpectacleToDelete = (spect: ISpectacle) => {
        confirm({
            title: 'Suppression du spectacle',
            message: "Etes vous sûr de vouloir supprimer le spectacle ?",
            confirmText: "Supprimer",
            confirmColor: "primary",
            cancelColor: "danger"
        }).then((res: any) => {
            if (res) {
                return SpectaclesService.DeleteSpectacle(spect).then(load);
            }
        });
    }


    const saveChangeFolder = (value: string) => {
        if (spectacleEdition)
            setSpectacleEdition({ ...spectacleEdition, PictureFolder: value });
    }


    const saveChangeMainPicture = (value: string) => {
        if (spectacleEdition)
            setSpectacleEdition({ ...spectacleEdition, MainPictureUrl: value });
    }

    return <div className={Styles.AdminSpectacles}>
        <Container>

            <Button color="primary" onClick={setSpectacleEdition.bind(null, {} as ISpectacle)}>Créer un spectacle</Button>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Titre</th>
                        <th>Editer</th>
                        <th>Supprimer</th>
                    </tr>
                </thead>
                <tbody>
                    {spectacles.map(s => {
                        return <tr key={s.Id} className={Styles.SpectacleRow}>
                            <th scope="row">{s.Id}</th>
                            <td>{s.Title}</td>
                            <td><Button color="primary" onClick={setSpectacleEdition.bind(null, s)}>Editer</Button></td>
                            <td><Button color="danger" onClick={setSpectacleToDelete.bind(null, s)}>Supprimer</Button></td>
                        </tr>
                    })}
                </tbody>

            </Table>
            {spectacleEdition ?

                <div className={Styles.Edition}>
                    {spectacleEdition.Id ? <h2>Edition du spectacle {spectacleEdition.Id}</h2> : <h2>Création de spectacle</h2>}
                    <Form>
                        <FormGroup>
                            <Label for="inputTitle">Titre</Label>
                            <Input id="inputTitle" placeholder="Titre" value={spectacleEdition.Title} onChange={(newText) => {
                                if (spectacleEdition)
                                    setSpectacleEdition({ ...spectacleEdition, Title: newText.target.value })
                            }} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="inputContent">Contenu</Label>
                            <Editor onChange={(newText) => {
                                if (spectacleEdition)
                                    setSpectacleEdition({ ...spectacleEdition, Content: newText})

                            }
                            } initialValue={spectacleEdition.Content} />


                        </FormGroup>
                        <FormGroup>
                            <Label for="inputPictureUrl">Image principale</Label>
                            <FileFolderSelector filter={isPicture} onSelect={saveChangeMainPicture} value={spectacleEdition.MainPictureUrl} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inputPictureFolder">Dossier d'images</Label>
                            <FileFolderSelector folder onSelect={saveChangeFolder} value={spectacleEdition.PictureFolder} />
                            {spectacleEdition.Pictures ?
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {spectacleEdition.Pictures.map(p => <div><img src={p.Url} width={200} />  <Input id="inputTitlePicture" placeholder="Titre" value={p.Title} onChange={(newText) => {
                                        if (spectacleEdition)
                                            setSpectacleEdition({ ...spectacleEdition, Pictures: spectacleEdition.Pictures.map(pict => { return pict.Url != p.Url ? pict : { Url: p.Url, Title: newText.target.value } }) })
                                    }} /></div>)}
                                </div>
                                : null}
                        </FormGroup>
                    </Form>
                    <Button color="primary" onClick={saveSpectacle.bind(null, spectacleEdition)}>Sauvegarder</Button>
                    <Button color="danger" onClick={() => setSpectacleEdition(undefined)}>Annuler</Button>
                </div>
                : null
            }
        </Container>
    </div>
}


export default AdminSpectacles;
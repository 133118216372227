import React from "react";
import { Form, FormGroup, Label, Input, Container, Button, Col, Row } from "reactstrap";
import contactservice from "services/contactservice";
import Styles from "./contact.module.scss";
interface IContactProps { }

interface IContactState {
  nom?: string;
  prenom?: string;
  email?: string;
  sujet?: string;
  corps?: string;
  isSended: boolean;
}

class Contact extends React.Component<IContactProps, IContactState> {
  /**
   *
   */
  constructor(props: IContactProps) {
    super(props);

    this.state = { isSended: false };
  }

  componentDidMount() {

  }

  render() {
    return <Container className={Styles.contact}>
      <p className={Styles.title}>Pour nous contacter, vous pouvez remplir le formulaire ci-dessous ou nous <a href="mailto:cierelativement@gmail.com">contacter directement par email</a>.</p>
      <span className="small mb-3 d-block">Tous les champs sont obligatoires.</span>
      <Form>

        <FormGroup>
          <Row>
            <Col xs="12" sm="6">
              <Label for="nom">Votre nom :</Label>
              <Input type="text" name="nom" id="nom" placeholder="Nom" onChange={(res) => {
                this.setState({ nom: res.target.value || undefined })
              }} />
            </Col>
            <Col xs="12" sm="6">
              <Label for="prenom">Votre prénom :</Label>
              <Input type="text" name="prenom" id="prenom" placeholder="Prénom" onChange={(res) => {
                this.setState({ prenom: res.target.value || undefined })
              }} />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label for="email">Votre adresse mail :</Label>
          <Input type="email" name="email" id="email" placeholder="E-mail" onChange={(res) => {
            this.setState({ email: res.target.value || undefined })
          }} />
        </FormGroup>
        <FormGroup>
          <Label for="sujet">Le sujet de votre message :</Label>
          <Input type="text" name="sujet" id="sujet" placeholder="Sujet" onChange={(res) => {
            this.setState({ sujet: res.target.value || undefined })
          }} />
        </FormGroup>
        <FormGroup>
          <Label for="corps">Votre message :</Label>
          <Input type="textarea" name="corps" id="corps" onChange={(res) => {
            this.setState({ corps: res.target.value.replace(/\n/gi, '<br />') || undefined })
          }} />
        </FormGroup>

        <FormGroup className="justify-content-center my-5 text-center">
          <Button onClick={this.sendMail.bind(this)} color={this.isSendActive() ? 'primary' : 'primary'} size="lg" disabled={!this.isSendActive()}>Envoyer le message</Button>
        </FormGroup>
        {this.state.isSended ?
          <FormGroup>
            <Label >Merci pour votre contact, nous reviendrons vers vous dès que possible.</Label>
          </FormGroup>
          : null}

      </Form>
    </Container>
  }

  public isSendActive() {
    return this.state.nom &&
      this.state.prenom &&
      this.state.email &&
      this.state.sujet &&
      this.state.corps && !this.state.isSended;
  }

  public sendMail() {
    if (this.isSendActive()) {
      contactservice.SendContact({
        nom: this.state.nom!,
        prenom: this.state.prenom!,
        email: this.state.email!,
        message: this.state.corps!,
        sujet: this.state.sujet!
      }).then(() => {
        this.setState({
          isSended: true,
          corps: undefined,
          email: undefined,
          nom: undefined,
          prenom: undefined,
          sujet: undefined
        })
      })
    }
  }
}

export default Contact;

import React, { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import Styles from "./adminFiles.module.scss";
import { Container } from 'reactstrap';
import config from 'services/config.json';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import FileExplorer, { computeFullPath, isPicture } from 'components/common/fileExplorer/fileExplorer';
//https://www.uptickhq.com/react-keyed-file-browser/


const AdminFiles = () => {

  return <div className={Styles.AdminFiles}>
    <Container>
      <div style={{ height: '480px' }}>

        <FileExplorer

          onDblClick={
            (any: any) => {
              if (any.rowData.type == 'file') {
                var input = document.getElementById('copyPaste') as HTMLInputElement;
                input.value = `${config.wsUrl != '' ? config.wsUrl : window.location.origin}/${config.rootFolderPath}${computeFullPath(any.rowData)}`;
                input.select();
                document.execCommand("copy");
                toast("L'url de l'élément a été copiée dans le presse papier", { type: "success" });
              }
            }
          }
        />
        <input id="copyPaste" type="text" />
      </div>

    </Container>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
    />
  </div>
}


export default AdminFiles;
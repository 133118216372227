import React, { useEffect, useState } from "react";
import Styles from "./agenda.module.scss";
import IActualite from "model/actualite";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ActualitesService from "services/actualitesService";
import { Container, ListGroup, ListGroupItem, Button, Row, Col } from "reactstrap";
import dateFormat from 'dateformat';
import { render } from "react-dom";

const Agenda = () => {
  const [events, setEvents] = useState<IActualite[]>([]);

  useEffect(() => {
    ActualitesService.GetAgenda().then((agenda) => {
      setEvents(agenda)
    })
  }, []);


  if (events.length) {
    return (
      <section className="sectionAgenda">
        <h2>Agenda</h2>
        <div className={Styles.Agenda}>
          <Container>
            <Row>
              <Col xs="12" lg={{ size: 8, offset: 2 }}>
                <ListGroup>
                  {events.map(e => {
                    return <ListGroupItem key={e.Id} >
                      <div className="evenement-item">
                        <div className="col-xs-12 col-md-3 date-heure">
                          <span className={Styles.Date}>
                            {e.StartDate ? dateFormat(e.StartDate, "dd/mm") : null}
                          </span>
                          <span className="horaire">{e.StartDate ? dateFormat(e.StartDate, "HH:MM") : null}</span>
                        </div>
                        <div className="col-xs-12 col-md-5">
                          <p className={Styles.Title}>
                            {e.Title}
                          </p>
                        </div>

                        <div className="col-xs-12 col-md-4">
                          <Button className="btn btn-primary" onClick={() => window.location.href = `/#/actualite/${e.Id}`}>En savoir plus</Button>

                        </div>

                      </div>
                    </ListGroupItem>
                  })}


                </ListGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
  else {
    return null;
  }

}

export default Agenda;

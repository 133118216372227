import React from 'react';
import Spectacle from 'components/spectacle/spectacle';

class SpectaclePage extends React.Component<{ setHeaderValues: Function, match: any }>
{

  render() {
    return (
      <div>
        <Spectacle id={this.props.match.params.id} setHeaderValues={this.props.setHeaderValues} />
      </div>
    );
  }

}

export default SpectaclePage;

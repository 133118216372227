import React from "react";
import Styles from "./spectacles.module.scss";
import ISpectacle from "model/spectacle";
import SpectaclesService from "services/spectaclesService";
import { Container, Col, Row, Button, Card, CardBody, CardImg, CardTitle } from 'reactstrap';

interface ISpectaclesProps {

}

interface ISpectaclesState {
    spectacles: ISpectacle[]
}

class Spectacles extends React.Component<ISpectaclesProps, ISpectaclesState> {

    /**
     *
     */
    constructor(props: ISpectaclesProps) {
        super(props);

        this.state = { spectacles: [] };
    }

    componentDidMount() {
        SpectaclesService.GetAllSpectacles().then(spectacles => {
            this.setState({ spectacles: spectacles });
        })
    }

    render() {
        return (
            <div className={Styles.Spectacles}>
                <Container>
                    <Row className="my-5">
                        {this.state.spectacles.map(s =>
                            <Col key={s.Id} xs="12" md="6">
                                <Card>
                                    <a href={`/#/spectacle/${s.Id}`} className="c-preview"><div className="c-preview__img" ><CardImg top width="100%" src={s.MainPictureUrl} /></div></a>
                                    <CardBody>
                                        <CardTitle>{s.Title}</CardTitle>
                                        <a href={`/#/spectacle/${s.Id}`} className="card-link underlined underlined--thick">En savoir +</a>
                                    </CardBody>
                                </Card>
                            </Col>)}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Spectacles
import React, { useEffect, useState } from 'react'

import Styles from "./adminRelatifs.module.scss";
import { Table, Button, Input, Form, FormGroup, Label, Container } from 'reactstrap';
import Editor from 'components/common/editor/editor';
import confirm from 'reactstrap-confirm';
import IRelatifs from 'model/lesRelatifs';
import relatifsService from 'services/relatifsService';
import IFileInfo from 'model/fileInfo';
import { isPicture } from 'components/common/fileExplorer/fileExplorer';
import FileFolderSelector from 'components/common/fileFolderSelector/fileFolderSelector';


const AdminRelatifs = () => {
    const [relatifEdition, setRelatifEdition] = useState<IRelatifs | undefined>(undefined)
    const [relatifs, setRelatifs] = useState<IRelatifs[]>([]);

    const load = () => {
        relatifsService.GetAllRelatifs()
            .then(value => {
                setRelatifs(value.sort((a: IRelatifs, b: IRelatifs) => { return a.Id < b.Id ? -1 : 1 }));
                setRelatifEdition(undefined);
            })
    }

    useEffect(() => {
        load();
    }, []);


    const saverelatif = (relatif: IRelatifs) => {
        relatifsService.SetRelatif(relatif).then(load);
    }


    const setrelatifToDelete = (relatif: IRelatifs) => {
        confirm({
            title: 'Suppression du relatif',
            message: "Etes vous sûr de vouloir supprimer le relatif ?",
            confirmText: "Supprimer",
            confirmColor: "primary",
            cancelColor: "danger"
        }).then((res: any) => {
            if (res) {
                return relatifsService.DeleteRelatif(relatif).then(load);
            }
        });
    }

    const saveChangePicture = (value: string) => {
        if (relatifEdition)
            setRelatifEdition({ ...relatifEdition, PictureUrl: value });
    }

    const saveChangeDescriptifCourt = (value: string) => {
        if (relatifEdition)
            setRelatifEdition({ ...relatifEdition, DescriptionCourte: value });
    }

    return <div className={Styles.AdminRelatifs}>
        <Container>

            <Button color="primary" onClick={setRelatifEdition.bind(null, {} as IRelatifs)}>Créer un relatif</Button>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Titre</th>
                        <th>Editer</th>
                        <th>Supprimer</th>
                    </tr>
                </thead>
                <tbody>
                    {relatifs.map(s => {
                        return <tr key={s.Id} className={Styles.relatifRow}>
                            <th scope="row">{s.Id}</th>
                            <td>{s.Name}</td>
                            <td><Button color="primary" onClick={setRelatifEdition.bind(null, s)}>Editer</Button></td>
                            <td><Button color="danger" onClick={setrelatifToDelete.bind(null, s)}>Supprimer</Button></td>
                        </tr>
                    })}
                </tbody>

            </Table>
            {relatifEdition ?

                <div className={Styles.Edition}>
                    {relatifEdition.Id ? <h2>Edition du relatif {relatifEdition.Id}</h2> : <h2>Création de relatifs</h2>}
                    <Form>
                        <FormGroup>
                            <Label for="inputTitle">Nom</Label>
                            <Input id="inputTitle" placeholder="Nom" value={relatifEdition.Name} onChange={(newText) => {
                                if (relatifEdition)
                                    setRelatifEdition({ ...relatifEdition, Name: newText.target.value })
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inputPictureUrl">Image principale</Label>
                            <FileFolderSelector filter={isPicture} onSelect={saveChangePicture} value={relatifEdition.PictureUrl} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inputContent">Description courte</Label>
                            <Editor id="editorCourt" onChange={(newText) => {
                                if (relatifEdition)
                                    setRelatifEdition({ ...relatifEdition, DescriptionCourte: newText })

                            }} initialValue={relatifEdition.DescriptionCourte} />

                        </FormGroup>
                        <FormGroup>
                            <Label for="inputContentLong">Description longue</Label>
                            <Editor id="editorLong" onChange={(newText) => {
                                if (relatifEdition)
                                    setRelatifEdition({ ...relatifEdition, DescriptionLongue: newText })

                            }} initialValue={relatifEdition.DescriptionLongue} />

                        </FormGroup>
                        <FormGroup>
                            <Label for="inputMail">Email</Label>
                            <Input id="inputMail" placeholder="Email" value={relatifEdition.Email} onChange={(newText) => {
                                if (relatifEdition)
                                    setRelatifEdition({ ...relatifEdition, Email: newText.target.value })
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="inputSite">Site perso</Label>
                            <Input id="inputSite" placeholder="Site" value={relatifEdition.Site} onChange={(newText) => {
                                if (relatifEdition)
                                    setRelatifEdition({ ...relatifEdition, Site: newText.target.value })
                            }} />
                        </FormGroup>
                    </Form>
                    <Button color="primary" onClick={saverelatif.bind(null, relatifEdition)}>Sauvegarder</Button>
                    <Button color="danger" onClick={() => setRelatifEdition(undefined)}>Annuler</Button>
                </div>
                : null
            }
        </Container>
    </div>

}

export default AdminRelatifs;
import React from 'react';
import Styles from './social.module.scss';
import FbLogin from 'components/FacebookLogin/fbLogin';

class Social extends React.Component {
  render() {
    return (
      <div className={Styles.Social}>
        <FbLogin setHeaderValues={() => { }} />
        {/* <Editor /> */}
      </div>
    );
  }

}

export default Social;

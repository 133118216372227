import React from 'react';
import Header from 'components/common/header/header';
import Contact from 'components/contact/contact';

class ContactPage extends React.Component<{ setHeaderValues: Function }> {

  componentDidMount() {
    this.props.setHeaderValues({ headerUrl: 'contact', headerTitle: undefined, headerPictureUrl: undefined })
  }

  render() {
    return (
      <div>
        <Contact />
      </div>
    );
  }

}

export default ContactPage;

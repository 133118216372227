import React, { EventHandler, useEffect, useState } from 'react';
import { Editor as Ed } from '@tinymce/tinymce-react';

interface IEditorProps {
  initialValue: string,
  onChange: EventHandler<any>,
  id?: string
}

const Editor = (props: IEditorProps) => {
  const [initialValue, setInitialValue] = useState(props.initialValue);
  const [value, setValue] = useState(props.initialValue || '');

  return (
    <div className="form-control" style={{ height: "auto" }}>
      <Ed id={props.id || "inputContent"} apiKey="3aj2ldybevt7vbax09m0tog3p3um5tkqqal2jyxooqumg7p4"
        initialValue={initialValue}
        value={value}
        inline={true}
        onEditorChange={(value, editor) => {
          setValue(value);
          props.onChange(editor.getContentAreaContainer().innerHTML);
        }
        }
        init={{
          plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help',
          toolbar: 'formatselect | bold italic strikethrough underline forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
          image_advtab: true,
          language: 'fr_FR',
          spellchecker_language: 'fr_FR'
        }} />
    </div>
  );
}


export default Editor;

import React from 'react';
import Header from 'components/common/header/header';
import Decors from 'components/decors/decors';

class DecorsPage extends React.Component<{ setHeaderValues: Function }> {

  componentDidMount() {
    this.props.setHeaderValues({ headerUrl: 'decors', headerTitle: undefined, headerPictureUrl: undefined })
  }

  render() {
    return (
      <div>
        <Decors />
      </div>
    );
  }

}

export default DecorsPage;

import ISpectacle from "model/spectacle";
import config from "./config.json";
import IFileInfo from "model/fileInfo";

class SpectaclesService {
    public static GetAllSpectacles(): Promise<ISpectacle[]> {
        return fetch(`${config.wsUrl}/api/spectacles/GetAllSpectacles`).then(resp => {
            return resp.json().then(res => { return res.map(this.ProcessPictureFolder) });
        });
    }

    public static GetSpectacle(id: number) {
        return fetch(`${config.wsUrl}/api/spectacles/GetSpectacle/${id}`).then(resp => {
            return resp.json().then(res => { return this.ProcessPictureFolder(res) });
        });
    }

    public static GetPictureFolder(): Promise<IFileInfo[]> {
        return fetch(`${config.wsUrl}/api/spectacles/GetPictureFolder`).then(resp => {
            return resp.json();
        });
    }

    public static SetSpectacle(spectacle: ISpectacle) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/spectacles/SetSpectacle`, { method: "post", body: JSON.stringify({ ...spectacle, token: localStorage.getItem("AuthToken") }), headers: myHeaders })
    }

    public static DeleteSpectacle(spectacle: ISpectacle) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/spectacles/DeleteSpectacle/${spectacle.Id}`, { method: "post", body: JSON.stringify({ token: localStorage.getItem("AuthToken") }), headers: myHeaders });
    }


    private static ProcessPictureFolder(decor: ISpectacle) {
        return {
            ...decor,
            Pictures: decor.Pictures.map(p => { return { Url: `${config.wsUrl}/${p.Url}`, Title: p.Title } })
        } as ISpectacle
    }
}

export default SpectaclesService;

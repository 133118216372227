import IDecor from "model/decor";
import config from "./config.json";
import IFileInfo from "model/fileInfo";

class DecorsService {
    public static GetAllDecors(): Promise<IDecor[]> {
        return fetch(`${config.wsUrl}/api/decors/GetAllDecors`).then(resp => {
            return resp.json().then(res => { return res.map(this.ProcessPictureFolder) });
        })
    }

    public static GetPictureFolder(): Promise<IFileInfo[]> {
        return fetch(`${config.wsUrl}/api/decors/GetPictureFolder`).then(resp => {
            return resp.json();
        });
    }



    public static GetDecor(id: number) {
        return fetch(`${config.wsUrl}/api/decors/GetDecor/${id}`).then(resp => {
            return resp.json().then(res => { return this.ProcessPictureFolder(res) });;
        })
    }

    public static SetDecor(decor: IDecor) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/decors/SetDecor`, { method: "post", body: JSON.stringify({ ...decor, token: localStorage.getItem("AuthToken") }), headers: myHeaders })
    }

    public static DeleteDecor(decor: IDecor) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/decors/DeleteDecor/${decor.Id}`, { method: "post", body: JSON.stringify({ token: localStorage.getItem("AuthToken") }), headers: myHeaders });

    }

    private static ProcessPictureFolder(decor: IDecor) {
        return {
            ...decor,
            Pictures: decor.Pictures.map(p => { return { Url: `${config.wsUrl}/${p.Url}`, Title: p.Title } })
        } as IDecor
    }
}

export default DecorsService;

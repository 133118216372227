import React from 'react';
import CarrouselActus from 'components/carrouselActus/carrouselActus';
import Actualites from 'components/actualites/actualites';
import Agenda from 'components/agenda/agenda';
import Presentation from 'components/presentation/presentation';
import { Row, Col, UncontrolledDropdown } from 'reactstrap';
class AccueilPage extends React.Component<{ setHeaderValues: Function }> {

  componentDidMount() {
    this.props.setHeaderValues({ headerUrl: undefined, headerPictureUrl: undefined, headerTitle: undefined })
  }

  render() {
    return (
      <div>
        <CarrouselActus />
        <Actualites limit={true} />
        <div className="p-0">
          <Presentation />
        </div>

        
          <Col xs="12" className="w-100">
            <Agenda />
          </Col>
        
      </div>
    );
  }
}

export default AccueilPage;

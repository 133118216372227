import React from 'react';
import AdminActus from 'components/admin/adminActus/adminActus';
import Header from 'components/common/header/header';
import AdminFiles from 'components/admin/adminFiles/adminFiles';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import { Container } from 'reactstrap';
import AdminSpectacles from 'components/admin/adminSpectacles/adminSpectacles';
import AdminDecors from 'components/admin/adminDecors/adminDecors';
import AdminRelatif from 'components/admin/adminRelatif/adminRelatifs';
import AdminGeneral from 'components/admin/adminGeneral/adminGeneral';
interface IAdminPageState {
}

interface IAdminPageProps {
    setHeaderValues: Function
}

class AdminPage extends React.Component<IAdminPageProps, IAdminPageState>{
    /**
     *
     */
    constructor(props: IAdminPageProps) {
        super(props);

    }

    componentDidMount() {
        this.props.setHeaderValues({ headerUrl: 'admin', headerTitle: undefined, headerPictureUrl: undefined })
    }


    render() {
        return (
            <div>
                <Container className="admin">
                    <Tabs>
                        <TabList>
                            <Tab>Général</Tab>
                            <Tab>Actualités</Tab>
                            <Tab>Spectacles</Tab>
                            <Tab>Decors</Tab>
                            <Tab>Les relatifs</Tab>
                            <Tab>Fichiers</Tab>
                        </TabList>

                        <TabPanel>
                            <AdminGeneral />
                        </TabPanel>
                        <TabPanel>
                            <AdminActus />
                        </TabPanel>
                        <TabPanel>
                            <AdminSpectacles />
                        </TabPanel>
                        <TabPanel>
                            <AdminDecors />
                        </TabPanel>
                        <TabPanel>
                            <AdminRelatif />
                        </TabPanel>
                        <TabPanel>
                            <AdminFiles />
                        </TabPanel>
                    </Tabs>
                </Container>
            </div>
        );
    }

}

export default AdminPage;

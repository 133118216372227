import React, { useEffect, useState } from 'react';
import Styles from './actualites.module.scss';
import IActualite from 'model/actualite';
import ActualiteCard from './actualiteCard';
import ActualitesService from 'services/actualitesService';
import { Container, Col, Row, Button } from 'reactstrap';
// First way to import
import { CircleLoader } from 'react-spinners';
interface IActualitesProps {
  limit: boolean
}


const Actualites = (props: IActualitesProps) => {
  const [actus, setActus] = useState<IActualite[]>([]);
  const [limit, setLimit] = useState<boolean>(props.limit);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (limit ? ActualitesService.GetLastActus() : ActualitesService.GetAllActus()).then(actus => { setActus(actus); setIsLoading(false) });
  }, [])


  return (
    <div className={Styles.Actualites}>
      {/* {this.state.isLoading ?  <Container></Container> :  */}
      <Container>
        <CircleLoader
          css={"margin: auto; margin-top: 20px;"}
          sizeUnit={"vw"}
          size={20}
          color={'rgb(95, 156, 148)'}
          loading={isLoading}
        />
        <Row className="my-5">
          {actus.map(a => <Col key={a.Id} xs="12" md="4"> <ActualiteCard actu={a} /></Col>)}
        </Row>
        {limit ?
          <Row className="justify-content-center my-5">
            <Button color="primary" onClick={() => window.location.href = "/#/actualites"}>Voir toutes les actualités</Button>
          </Row>
          :
          null
        }


      </Container>
      {/* } */}
    </div>
  );
}

export default Actualites;

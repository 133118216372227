import React, { useEffect, useState } from 'react'
import { Input, Form, FormGroup, Label, Container, Button } from 'reactstrap';
import { isPicture } from 'components/common/fileExplorer/fileExplorer';
import FileFolderSelector from 'components/common/fileFolderSelector/fileFolderSelector';
import IGeneral from 'model/general';
import GeneralService from 'services/generalService';
import Editor from 'components/common/editor/editor';

const AdminGeneral = () => {

    const [general, setGeneral] = useState<IGeneral | undefined>(undefined);

    const load = () => {
        GeneralService.GetGeneral()
            .then(setGeneral);
    }

    useEffect(() => {
        load();
    }, []);

    const saveGeneral = () => {
        if (general)
            GeneralService.SetGeneral(general).then(load);
    }

    const saveChangeMainPicture = (key: string, value: string) => {
        if (!general)
            return;
        var newPage = {
            ...general.Pages,
            [key]: {
                ...general.Pages[key],
                PictureUrl: value
            }
        }
        setGeneral(
            {
                ...general,
                Pages: newPage
            }
        );
    }

    const saveChangeTitle = (key: string, value: string) => {
        if (!general)
            return;
        var newPage = {
            ...general.Pages,
            [key]: {
                ...general.Pages[key],
                Title: value
            }
        }
        setGeneral(
            {
                ...general,
                Pages: newPage
            }
        );
    }

    if (general) {

        return (<div >
            <Container className="admin">
                <h1>Pages</h1>
                {Object.entries(general.Pages).map(([key, value]) => {
                    return <div key={key}>
                        <h2>Page : {key}</h2>
                        <Form>
                            <FormGroup>
                                <Label>Titre :</Label>
                                <Input type="text" defaultValue={value.Title} onChange={(newText) => {
                                    saveChangeTitle(key, newText.target.value)
                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Image :</Label>
                                <FileFolderSelector filter={isPicture} onSelect={saveChangeMainPicture.bind(null, key)} value={value.PictureUrl} />
                            </FormGroup>
                        </Form>
                        <hr />
                    </div>
                })}
                <h1>Présentation</h1>
                <div className="presentation">
                    <div className="presentationHaut">
                        <h3>Présentation haut</h3>
                        <Editor id="edhaut" onChange={(newText) => {
                            if (general) {
                                setGeneral({
                                    ...general,
                                    PresentationHaut: newText
                                }
                                )
                            }
                        }} initialValue={general.PresentationHaut} />
                    </div>
                    <div className="presentationMilieu">
                        <div className="presentationGauche">
                            <h3>Présentation gauche</h3>
                            <FormGroup>
                                <Label>Image :</Label>
                                <FileFolderSelector filter={isPicture} onSelect={(newText: string) => {
                                    if (general)
                                        setGeneral({
                                            ...general,
                                            PresentationPhotoGauche: newText
                                        }
                                        )
                                }} value={general.PresentationPhotoGauche} />
                            </FormGroup>
                            <Editor id="edgauche" onChange={(newText) => {
                                if (general) {
                                    setGeneral({
                                        ...general,
                                        PresentationGauche: newText
                                    }
                                    )
                                }
                            }} initialValue={general.PresentationGauche} />

                        </div>
                        <div className="presentationDroite">
                            <h3>Présentation droite</h3>
                            <FormGroup>
                                <Label>Image :</Label>
                                <FileFolderSelector filter={isPicture} onSelect={(newText: string) => {
                                    if (general)
                                        setGeneral({
                                            ...general,
                                            PresentationPhotoDroite: newText
                                        }
                                        )
                                }} value={general.PresentationPhotoDroite} />
                            </FormGroup>
                            <Editor id="eddroite" onChange={(newText) => {
                                if (general) {
                                    setGeneral({
                                        ...general,
                                        PresentationDroite: newText
                                    }
                                    )
                                }
                            }} initialValue={general.PresentationDroite} />

                        </div>
                    </div>
                    <div className="presentationBas">
                        <h3>Présentation bas</h3>
                        <Editor id="edbas" onChange={(newText) => {
                            if (general) {
                                setGeneral({
                                    ...general,
                                    PresentationBas: newText
                                }
                                )
                            }
                        }} initialValue={general.PresentationBas} />
                    </div>
                </div>
                <h1>Contact</h1>
                <Editor id="edcontact" onChange={(newText) => {
                    if (general) {
                        setGeneral({
                            ...general,
                            Contact: newText
                        }
                        )
                    }
                }} initialValue={general.Contact} />

                <h1>Bas de page</h1>
                <Editor id="edfooter" onChange={(newText) => {
                    if (general) {
                        setGeneral({
                            ...general,
                            Footer: newText
                        }
                        )
                    }
                }} initialValue={general.Footer} />

                <div className="mt-5 text-right">
                    <Button color="danger" onClick={() => load()} className="mr-3">Annuler</Button>
                    <Button color="primary" onClick={saveGeneral}>Sauvegarder</Button>
                </div>
            </Container>
        </div>)
    }



    return null;

}

export default AdminGeneral;
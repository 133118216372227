import React from 'react';
import Decor from 'components/decor/decor';

class DecorPage extends React.Component<{ setHeaderValues: Function, match: any }>
{

  render() {
    return (
      <div>
        <Decor id={this.props.match.params.id} setHeaderValues={this.props.setHeaderValues} />
      </div>
    );
  }

}

export default DecorPage;

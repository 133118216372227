import IRelatif from "model/lesRelatifs";
import config from "./config.json";

class RelatifService {

    public static GetAllRelatifs(): Promise<IRelatif[]> {
        return fetch(`${config.wsUrl}/api/relatif/GetAllRelatifs`).then(resp => {
            return resp.json();
        })
    }

    public static GetRelatif() {
        return fetch(`${config.wsUrl}/api/relatif/GetRelatif`).then(resp => {
            return resp.text();
        })
    }

    public static SetRelatif(relatif: IRelatif) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/relatif/SetRelatif`, { method: "post", body: JSON.stringify({ ...relatif, token: localStorage.getItem("AuthToken") }), headers: myHeaders })
    }

    public static DeleteRelatif(relatif: IRelatif) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        return fetch(`${config.wsUrl}/api/relatif/DeleteRelatif/${relatif.Id}`, { method: "post", body: JSON.stringify({ token: localStorage.getItem("AuthToken") }), headers: myHeaders });

    }
}

export default RelatifService;

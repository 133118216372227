import React, { useEffect, useState } from "react";
import Styles from "./footer.module.scss";
import { Markup } from "interweave";
import { Container } from 'reactstrap';
import GeneralService from "services/generalService";


interface IFooterState {
    footerText: string
}

const Footer = () => {

    const [footerText, setFooterText] = useState('');

    useEffect(() => {
        GeneralService.GetGeneral().then(gen => {
            setFooterText(gen.Footer);
        });
    }, []);

    return (
        <div className={Styles.Footer}>
            <Container>
                <Markup content={footerText} />
            </Container>
        </div>
    );
}

export default Footer;
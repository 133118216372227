import React from "react";
import Styles from "./decors.module.scss";
import IDecor from "model/decor";
import DecorsService from "services/decorsService";
import { Container, Col, Row, Card, CardBody, CardImg, CardTitle } from 'reactstrap';

interface IDecorsProps {

}

interface IDecorsState {
    decors: IDecor[];
}

class Decors extends React.Component<IDecorsProps, IDecorsState> {

    /**
     *
     */
    constructor(props: IDecorsProps) {
        super(props);

        this.state = { decors: [] };
    }

    componentDidMount() {
        DecorsService.GetAllDecors().then(decors => {
            this.setState({ decors: decors });
        })
    }

    render() {
        return (
            <div className={Styles.Decors}>
                <Container>
                    <Row className="my-5">
                        {this.state.decors.map(d =>
                            <Col key={d.Id} xs="12" md="6">
                                <Card>
                                    <a href={`/#/decor/${d.Id}`} className="c-preview"><div className="c-preview__img" ><CardImg top width="100%" src={d.MainPictureUrl} /></div></a>
                                    <CardBody>
                                        <CardTitle>{d.Title}</CardTitle>
                                        <a href={`/#/decor/${d.Id}`} className="card-link underlined underlined--thick">En savoir +</a>
                                    </CardBody>
                                </Card>
                            </Col>)}
                    </Row>
                </Container>
            </div>
        );

    }
}

export default Decors;
import React from 'react';
import Styles from './App.module.scss';
import { HashRouter as Router, Route } from 'react-router-dom';
import SocialPage from './pages/social.page';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container
} from 'reactstrap';
import AccueilPage from 'pages/accueil.page';
import ActualitesPage from 'pages/actualites.page';
import SpectaclesPage from 'pages/spectacles.page';
import DecorsPage from 'pages/decors.page';
import RelatifsPage from 'pages/relatifs.page';
import ContactPage from 'pages/contact.page';
import DecorPage from 'pages/decor.page';
import SpectaclePage from 'pages/spectacle.page';
import ActualitePage from 'pages/actualite.page';
import Footer from 'components/common/footer/footer';
import logorel from './assets/logo_cie_relativement.png';
import iconMenu from './assets/icon_menu.svg';
import AdminPage from 'pages/admin.page';
import GeneralService from 'services/generalService';
import Header from 'components/common/header/header';
import { IPage } from 'model/general';
import FbLogin from 'components/FacebookLogin/fbLogin';


interface IAppState {
  isOpen: boolean,
  contactText: string,
  headerUrl?: string, headerPictureUrl?: string, headerTitle?: string
}

class App extends React.Component<any, IAppState> {

  private Urls = [
    { url: "/#/actualites", title: "Actualités", activeUrls: ["actualites", "actualite"] },
    { url: "/#/spectacles", title: "Spectacles", activeUrls: ["spectacles", "spectacle"] },
    { url: "/#/decors", title: "Décors", activeUrls: ["decors", "decor"] },
    { url: "/#/relatif", title: "Les relatifs", activeUrls: ["relatif"] },
    { url: "/#/contact", title: "Contact", activeUrls: ["contact"] }
  ]

  constructor(props: any) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      contactText: '',
      headerUrl: undefined, headerPictureUrl: undefined, headerTitle: undefined
    };
  }


  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentDidMount() {
    GeneralService.GetGeneral().then(gen => {
      this.setState({ contactText: gen.Contact });
    })
  }

  render() {

    var urlPage = window.location.pathname.split("/")[1]

    let navItem = this.Urls.map(url => {
      return <NavItem key={url.url} >
        <NavLink active={this.state.headerUrl != undefined && url.activeUrls.indexOf(this.state.headerUrl) != -1} href={url.url}>{url.title}</NavLink>
      </NavItem>
    });

    let header;
    if (this.state.headerPictureUrl || this.state.headerTitle || this.state.headerUrl) {
      header = <Header UrlPage={this.state.headerUrl} Title={this.state.headerTitle} PictureUrl={this.state.headerPictureUrl} />
    }
    else {
      header = null;
    }

    return (
      <Router >
        <div className={Styles.App}>
          <Container fluid className="px-0">
            <Navbar expand="md">
              <NavbarBrand href="/#/">
                <img src={logorel} alt="Logo Cie Relativement" />
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle.bind(this)}><img src={iconMenu} alt="Menu" /><span>Menu</span></NavbarToggler>
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  {navItem}
                  <NavItem key={'infos'} className="d-flex flex-column align-items-start address" dangerouslySetInnerHTML={{ __html: this.state.contactText }}>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
            {header}
            <Route exact path="/adm/social" component={SocialPage} />
            <Route exact path="/" render={(props) => <AccueilPage {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
            <Route exact path="/actualites" render={(props) => <ActualitesPage {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
            <Route path="/actualite/:id" render={(props) => <ActualitePage {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
            <Route path="/spectacles" render={(props) => <SpectaclesPage {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
            <Route exact path="/spectacle/:id" render={(props) => <SpectaclePage {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
            <Route exact path="/decors" render={(props) => <DecorsPage {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
            <Route path="/decor/:id" render={(props) => <DecorPage {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
            <Route exact path="/relatif" render={(props) => <RelatifsPage {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
            <Route exact path="/contact" render={(props) => <ContactPage {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
            <Route exact path="/admin" render={(props) => <FbLogin {...props} setHeaderValues={this.setHeaderValues.bind(this)} />} />
          </Container>
        </div>

        <Footer />

      </Router>
    );
  }

  setHeaderValues(values: { headerUrl?: string, headerPictureUrl?: string, headerTitle?: string }) {
    this.setState(values);
  }

}

export default App;

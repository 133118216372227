import React from 'react';
import Actualite from 'components/actualite/actualite';


class ActualitePage extends React.Component<{ setHeaderValues: Function, match: any }> {


  render() {
    return (
      <div>
        <Actualite id={this.props.match.params.id} setHeaderValues={this.props.setHeaderValues} />
      </div>
    );
  }

}

export default ActualitePage;

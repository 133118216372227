import React from 'react';
import Header from 'components/common/header/header';
import Relatif from 'components/relatif/relatif';

class RelatifsPage extends React.Component<{ setHeaderValues: Function }> {

  componentDidMount() {
    this.props.setHeaderValues({ headerUrl: 'relatif', headerTitle: undefined, headerPictureUrl: undefined })
  }

  render() {
    return (
      <div>
        <Relatif isComplete={true} /></div>
    );
  }

}

export default RelatifsPage;
